import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom"; // Import necessary Router components
import Signup from "./components/Signup"; // Import the Signup component
import Login from "./components/Login";   // Import the Login component

const App = () => {
  return (
    <Router>
      <div>
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link> {/* Link to landing page */}
            </li>
            <li>
              <Link to="/signup">Sign Up</Link> {/* Link to signup page */}
            </li>
            <li>
              <Link to="/login">Login</Link> {/* Link to login page */}
            </li>
          </ul>
        </nav>

        {/* Define Routes for each page */}
        <Routes>
          <Route path="/" element={<Landing />} /> {/* Home page (landing) */}
          <Route path="/signup" element={<Signup />} /> {/* Sign-up page */}
          <Route path="/login" element={<Login />} /> {/* Login page */}
        </Routes>
      </div>
    </Router>
  );
};

// Landing page component
const Landing = () => {
  return (
    <div>
      <h1>Welcome to Our App!</h1>
      <p>Please sign up or log in to continue.</p>
      <Link to="/signup">
        <button>Sign Up</button>
      </Link>
      <Link to="/login">
        <button>Login</button>
      </Link>
    </div>
  );
};

export default App;